import './App.css';
import { useEffect, useState } from 'react';
import Addmoreinput from './components/Addmoreinput';
import BillingAddress from './components/BillingAddress';
import Main from './components/Main';

const App = () => {
  const [billingData, setBillingData] = useState({
    to: '',
    phone: '',
    location: '',
    type: 'Preventiv',
    invoiceNr: '',
    date: '',
    tax: 0,
    deposit: 0,
    total: 0,
  });
  const [isReady, setIsReady] = useState(false);
  const [inputList, setinputList] = useState([
    { id: 0, description: '', qty: 0, price: 0, total: 0 },
  ]);

  const onRefreshPDF = () => {
    if (!isReady) {
      setIsReady(true);
    } else {
      setIsReady(false);
      setTimeout(() => {
        setIsReady(true);
      }, 1000);
    }
  };

  useEffect(() => {
    inputList.reduce((accumulator, object) => {
      setBillingData({ ...billingData, total: accumulator + object.total });
      return accumulator + object.total;
    }, 0);
  }, [inputList]);

  const invoiceNrHandler = (e) => {
    setBillingData({ ...billingData, [e.target.name]: e.target.value });
  };

  return (
    <div className="App">
      <BillingAddress
        billingData={billingData}
        setBillingData={setBillingData}
      />
      <Addmoreinput inputList={inputList} setinputList={setinputList} />
      <div className="container">
        <div className="row">
          <div className="form-group col-sm-12 col-md-4">
            <input
              type="text"
              name="invoiceNr"
              className="form-control"
              placeholder={
                billingData.type
                  ? billingData.type + ' Numer'
                  : 'Number Preventivit'
              }
              onChange={(e) => invoiceNrHandler(e)}
            />
          </div>
          <div className="form-group col-sm-12 col-md-2 mt-2">
            <input
              type="number"
              name="tax"
              className="form-control"
              placeholder={'Tax'}
              onChange={(e) => invoiceNrHandler(e)}
            />
          </div>
          <div className="form-group col-sm-12 col-md-2 mt-2">
            <input
              type="number"
              name="deposit"
              className="form-control"
              placeholder="ParaPaguar"
              onChange={(e) => invoiceNrHandler(e)}
            />
          </div>
          <div className="col-4">
            <input
              type="number"
              name="total"
              value={billingData.total}
              className="form-control"
              placeholder={'total'}
              onChange={(e) => invoiceNrHandler(e)}
            />
          </div>
        </div>
      </div>
      <div>
        {isReady && (
          <Main
            subTotal={Number(billingData.total)}
            billingData={billingData}
            inputList={inputList}
            isReady={isReady}
          />
        )}
        <button className="generat-button" onClick={onRefreshPDF}>
          Rifresko
        </button>
      </div>
    </div>
  );
};

export default App;
