import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import logo from '../photos/logo-construction.jpeg';
import border from '../photos/border.png';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  border: {
    backgroundColor: 'red',
    height: '10px',
    width: '100%',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  logo: {
    height: '53px',
    width: '200px',
    backgroundColor: '#1F2E3C',
    borderRadius: '4px',
    marginBottom: '10px',
  },
  mt4: {
    marginTop: '10px',
  },
  colThree: {
    width: '16%',
  },
  colSix: {
    width: '50%',
  },
  borderLine: {
    borderWidth: 1,
    borderColor: '#000',
  },
  borderLeft: {
    borderLeftWidth: 1,
    borderColor: '#000',
    textAlign: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  dateContainer: {
    fontSize: 20,
    textTransform: 'uppercase',
  },
  billingInfo: {
    display: 'flex',
    color: '#1F2E3C',
    justifyContent: 'center',
    alignContent: 'center',
    textDecoration: 'underline',
    fontSize: 12,
  },
  myCustomText: {
    textDecoration: 'underline',
    fontSize: 10,
    color: '#000',
  },
  billType: {
    fontSize: 30,
    color: 'grey',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  notice: {
    position: 'relative',
    bottom: '20px',
    fontSize: 10,
    fontStyle: 'italic',
  },
  borderImg: {
    height: '15px',
    width: '100%',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  headerText: {
    fontSize: 30,
    opacity: 0.6,
  },
  headerSubTitle: {
    fontSize: 13,
    color: 'grey',
  },
  headerMainTitle: {
    fontSize: 15,
    marginTop: '5px',
    marginBottom: '10px',
    color: '#000',
  },
  contactDetails: {
    fontSize: 10,
    marginTop: '5px',
  },
});

const PDFFile = ({ billingData, inputList, subTotal }) => {
  const percentTax = billingData.tax / 100;
  const calculateTax = subTotal * (1 - percentTax);
  return (
    <Document>
      <Page style={styles.body}>
        <View>
          <Image style={styles.borderImg} src={border} />
        </View>
        <View style={styles.row}>
          <View>
            <Text style={styles.headerText}>{billingData.type}</Text>
            <Text style={styles.headerMainTitle}>LS Construction </Text>
            <Text style={styles.headerSubTitle}>Tirane , Kamez </Text>
            <Text style={styles.headerSubTitle}>Rruga Demokracia</Text>
            <Text style={styles.contactDetails}>
              <a href={'tel:+355689510795'}>+355689510795</a>
            </Text>
          </View>
          <View>
            <Image style={styles.logo} src={logo} />
            <View style={styles.textCenter}>
              <Text style={styles.billingInfo}>Data: {billingData.date}</Text>
              <Text style={styles.billingInfo}>
                {billingData.type} Nr: {billingData.invoiceNr}
              </Text>
            </View>
          </View>
        </View>
        <View style={{ ...styles.row, ...styles.mt4 }}>
          <View>
            <Text style={styles.billingInfo}>
              {billingData.to ? 'Fatura per' : ' '}
            </Text>
            <Text style={styles.myCustomText}>{billingData.to}</Text>
            <Text style={styles.contactDetails}> {billingData.phone}</Text>
          </View>
          <View>
            <Text style={{ ...styles.textCenter, ...styles.billingInfo }}>
              {billingData.location ? 'Vendodhja' : ' '}
            </Text>
            <Text style={styles.myCustomText}>{billingData.location}</Text>
          </View>
        </View>
        <View style={{ ...styles.row, ...styles.borderLine, ...styles.mt4 }}>
          <View style={styles.row}>
            <Text style={{ ...styles.colSix, ...styles.textCenter }}>
              Pershkrimi
            </Text>
            <Text style={{ ...styles.colThree, ...styles.borderLeft }}>
              Sasia
            </Text>
            <Text style={{ ...styles.colThree, ...styles.borderLeft }}>
              Cmimi per njesi
            </Text>
            <Text style={{ ...styles.colThree, ...styles.borderLeft }}>
              Totali
            </Text>
          </View>
        </View>
        {inputList.length > 0 &&
          inputList.map((list, index) => (
            <View key={index} style={{ ...styles.row, ...styles.borderLine }}>
              <View style={styles.row}>
                <Text style={styles.colSix}>
                  {list.description ? list.description : ' '}
                </Text>
                <Text style={{ ...styles.colThree, ...styles.borderLeft }}>
                  {list.qty > 0 ? list.qty : ''}
                </Text>
                <Text style={{ ...styles.colThree, ...styles.borderLeft }}>
                  {list.price > 0 ? `${list.price}` : ' '}
                </Text>
                <Text style={{ ...styles.colThree, ...styles.borderLeft }}>
                  {list.total > 0 ? `${list.total} Lek` : ' '}
                </Text>
              </View>
            </View>
          ))}
        <View
          style={{ ...styles.row, ...styles.borderLine, ...styles.flexEnd }}
        >
          <Text>Nen Totali: {subTotal} Lek </Text>
        </View>
        <View
          style={{ ...styles.row, ...styles.borderLine, ...styles.flexEnd }}
        >
          <Text>
            {billingData.deposit
              ? `Parapagim: ${billingData.deposit} Lek`
              : ' '}
          </Text>
        </View>
        <View
          style={{ ...styles.row, ...styles.borderLine, ...styles.flexEnd }}
        >
          <Text>Tax: {billingData.tax}%</Text>
        </View>
        <View
          style={{ ...styles.row, ...styles.borderLine, ...styles.flexEnd }}
        >
          <Text>Total: {calculateTax} Lek</Text>
        </View>
        <View>
          <Image style={{ ...styles.borderImg, ...styles.mt4 }} src={border} />
        </View>
        <View style={styles.notice}>
          <Text>
            Shenim: {billingData.type ?? 'Preventivi'} eshte i vlefshme per 20
            dite.
          </Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

export default PDFFile;
