import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFFile from './PDFFile';

const Main = ({ inputList, subTotal, billingData }) => {
  return (
    <PDFDownloadLink
      document={
        <PDFFile
          inputList={inputList}
          subTotal={subTotal}
          billingData={billingData}
        />
      }
      fileName={
        billingData.invoiceNr ? billingData.invoiceNr : 'ls-gips-fatura'
      }
    >
      <button className="btn btn-primary left">Shkarko</button>
    </PDFDownloadLink>
  );
};

export default Main;
