import React from 'react';

const BillingAddress = ({ billingData, setBillingData }) => {
  const handleinputchange = (e) => {
    setBillingData({ ...billingData, [e.target.name]: e.target.value });
  };
  return (
    <div className="container mb-5 mt-5">
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="row">
            <div className="form-group col-sm-12 col-md-6">
              <label>Zgjidh:</label>
              <input
                type="text"
                name="type"
                defaultValue="Preventiv"
                className="form-control"
                placeholder="Draft, Propozim , Fature"
                onChange={(e) => handleinputchange(e)}
              />
            </div>
            <div className="form-group col-sm-12 col-md-6">
              <label>Zgjidh Daten:</label>
              <input
                type="date"
                name="date"
                className="form-control"
                placeholder="Select Invoice Date"
                onChange={(e) => handleinputchange(e)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group col-12">
            <label>Fatura per:</label>
            <input
              type="text"
              name="to"
              className="form-control"
              placeholder="Fatura per"
              onChange={(e) => handleinputchange(e)}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group col-12">
            <label>Nr Kontaktit:</label>
            <input
              type="text"
              name="phone"
              className="form-control"
              placeholder="Nr Kontaktit"
              onChange={(e) => handleinputchange(e)}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group col-12">
            <label>Adresa:</label>
            <input
              type="text"
              name="location"
              className="form-control"
              placeholder="Adresa"
              onChange={(e) => handleinputchange(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingAddress;
