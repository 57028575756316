function Addmoreinput({ inputList, setinputList }) {
  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    list[index].id = index;
    list[index].total =
      Number(list[index].qty) * Number(Number(list[index].price));
    setinputList(list);
  };

  const handleremove = (index) => {
    const list = [...inputList];

    list.splice(index, 1);
    setinputList(list);
  };

  const handleaddclick = (index) => {
    setinputList([
      ...inputList,
      { id: index, description: '', qty: 0, price: 0, total: 0 },
    ]);
  };

  return (
    <section className="content container">
      <div className="row">
        <div className="col-sm-12">
          {inputList.map((x, i) => {
            return (
              <div id={i} className="row mb-3" key={i}>
                <div className="form-group col-md-4">
                  <label>Pershkrimi</label>
                  <input
                    type="text"
                    name="description"
                    className="form-control"
                    placeholder="Pershkrimi"
                    onChange={(e) => handleinputchange(e, i)}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Sasia</label>
                  <input
                    type="number"
                    name="qty"
                    min={0}
                    className="form-control"
                    placeholder="Shkruaj Sasine"
                    onChange={(e) => handleinputchange(e, i)}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Cmimi per njesi</label>
                  <input
                    type="number"
                    name="price"
                    min={0}
                    className="form-control"
                    placeholder="Cmimi per njesi"
                    onChange={(e) => handleinputchange(e, i)}
                  />
                </div>
                {inputList.length !== 1 && (
                  <div className="form-group col-md-2 mt-3">
                    <button
                      className="btn btn-danger mx-1"
                      onClick={() => handleremove(i)}
                    >
                      X
                    </button>
                  </div>
                )}
                <div className="form-group col-12 mt-3">
                  {inputList.length - 1 === i && (
                    <button
                      className="btn btn-success"
                      onClick={() => handleaddclick(inputList.length)}
                    >
                      Shto
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
export default Addmoreinput;
